import clsx from "clsx";
import EntryMeta from "components/EntryMeta";
import { widont } from "lib/helpers";
import { observer } from "mobx-react";

const EntryHeader = observer(({ entry, showSummary, showMeta }) => {
  const className = clsx("EntryHeader", `EntryHeader--${entry.headerTheme}`);

  return (
    <div className={className}>
      <div className="EntryHeader__content">
        <h1 className="EntryHeader__heading">{entry.title}</h1>
        {showSummary && (
          <div className="EntryHeader__summary">{widont(entry.summary)}</div>
        )}
        {showMeta && (
          <div className="EntryHeader__meta">
            <EntryMeta entry={entry} theme={entry.headerTheme} />
          </div>
        )}
      </div>
    </div>
  );
});

EntryHeader.defaultProps = {
  entry: null,
  showMeta: true,
  showSummary: false,
};

export default EntryHeader;
