import clsx from "clsx";
import { widont } from "lib/helpers";

const Summary = ({ summary }) => (
  <div className="Summary">
    <div
      className={clsx(
        "Summary__content",
        "pb-8 pt-8 font-sans text-xl font-medium md:px-6 md:pt-0 lg:text-2xl"
      )}
    >
      {widont(summary)}
    </div>
  </div>
);

export default Summary;
