/* eslint-disable react/prop-types */
import Ad from "@/components/Ad";
import ArticleHeading from "@/components/ArticleHeading";
import ArticleImages from "@/components/ArticleImages";
import ArticleModule from "@/components/ArticleModule";
import BodyAccordion from "@/components/BodyAccordion";
import BodyButtons from "@/components/BodyButtons";
import Callout from "@/components/Callout";
import FlexibleGrid from "@/components/FlexibleGrid";
import ImageBlock from "@/components/ImageBlock";
import InstagramPost from "@/components/InstagramPost";
import MagazineHero from "@/components/MagazineHero";
import PermissionPrompt from "@/components/PermissionPrompt";
import Player from "@/components/Player";
import Spacer from "@/components/Spacer";
import TextBlock from "@/components/TextBlock";
import VideoBlock from "@/components/VideoBlock";
import usePermission from "@/hooks/usePermission";
import colourways from "@/lib/colourways";
import clsx from "clsx";
import { useInView } from "react-hook-inview";

let adPlacement = 0;

const Body = ({ body, parent = null }) => {
  return (
    <div className="Body">
      <div className="Body__blocks">
        {body.map((block) => (
          <Block
            key={block.id}
            block={block}
            adPlacement={adPlacement}
            parent={parent}
          />
        ))}
      </div>
    </div>
  );
};

const Block = ({ block, parent = null }) => {
  const [ref, isVisible] = useInView({
    unobserveOnEnter: true,
  });

  return (
    <div ref={ref} className={clsx("ArticleBody__block")}>
      <Component block={block} parent={parent} />
    </div>
  );
};

const Component = ({ block, parent }) => {
  const hasInstagramPermission = usePermission("c:instagram");

  let image;

  switch (block.typeHandle) {
    case "callout":
      return <Callout heading={block.heading} text={block.text} />;

    case "text":
      const textColourwayString =
        block.textBackgroundColourway?.[0]?.colourwayHandle || "white";
      const textColourway = colourways(textColourwayString);

      return (
        <div
          className={clsx([`${textColourway.bg}`], {
            "text-white": textColourway.useLightText,
            "text-zinc-950": !textColourway.useLightText,
            "space-y-3 lg:space-y-6": block.headingSize === "sm",
            "space-y-4 lg:space-y-8": block.headingSize === "md",
            "space-y-5 lg:space-y-10": block.headingSize === "lg",
            "space-y-6 lg:space-y-12": block.headingSize === "xl",
            "space-y-8 lg:space-y-16": block.headingSize === "2xl",
          })}
        >
          {!!block.heading && (
            <ArticleHeading
              size={block.headingSize}
              element={block.headingElement}
              alignment="center"
            >
              {block.heading}
            </ArticleHeading>
          )}
          <TextBlock text={block.text} />
        </div>
      );

    case "heading":
      return (
        <ArticleHeading
          element={block.element}
          size={block.size}
          alignment="center"
        >
          {block.heading}
        </ArticleHeading>
      );

    case "image":
      image = block.image?.[0];

      return (
        <ImageBlock
          image={image}
          size={block.size}
          caption={block.caption}
          showInSidebar={block.showInSidebar}
          targetLink={block.targetLink}
        />
      );

    case "video":
      return (
        <VideoBlock
          brightcoveVideoId={block.brightcoveVideoId}
          embed={block.embed}
        />
      );

    case "musicPlayer":
      if (block.musicEntry.length === 0) return null;
      return <Player spotifyUri={block.musicEntry[0].spotifyUri} view="list" />;

    case "instagramPost":
      return hasInstagramPermission ? (
        <InstagramPost postUrl={block.postUrl} embedCode={block.embedCode} />
      ) : (
        <div className="mx-6 mb-6">
          <PermissionPrompt friendlyName="Instagram" />
        </div>
      );

    case "accordionSet":
      return (
        <div className="mx-6 my-6">
          <BodyAccordion items={block.accordions} />
        </div>
      );

    case "ad":
      adPlacement++;
      // Only show these ads on mobile
      return (
        <>
          <Ad
            placementName={`mob.co.uk_square_${adPlacement}`}
            className="md:hidden"
          />
        </>
      );
    case "hero":
      image = block.image?.[0];

      const colourwayHandle =
        block.backgroundColourway?.[0]?.colourwayHandle || "white";

      return (
        <MagazineHero
          image={image}
          imagePosition={block.imagePosition}
          eyebrow={block.eyebrow ?? parent?.title ?? ""}
          eyebrowHref={parent?.url ?? null}
          heading={block.heading}
          subHeading={block.subHeading}
          colourwayHandle={colourwayHandle}
          headingSize={block.headingSize}
        />
      );
    case "spacer":
      const backgroundColourwayHandle =
        block.backgroundColourway?.[0]?.colourwayHandle || "white";

      return (
        <Spacer
          size={block.size}
          backgroundColourwayHandle={backgroundColourwayHandle}
        />
      );

    case "horizontalRule":
      return <div className={clsx("border-b-2 border-zinc-950", {})}></div>;

    case "module":
      const textBackgroundColourwayHandle =
        block.textBackgroundColourway?.[0]?.colourwayHandle || "white";

      return (
        <div className={clsx("", {})}>
          <ArticleModule
            heading={block.heading}
            text={block.text}
            pullQuote={block.pullQuote}
            pullQuoteAttribution={block.pullQuoteAttribution}
            images={block.images}
            textPosition={block.textPosition}
            textBackgroundColourwayHandle={textBackgroundColourwayHandle}
            imageAspectRatio={block.imageAspectRatio}
            headingElement={block.headingElement}
            headingSize={block.headingSize}
            overlayHeading={block.overlayHeading}
          />
        </div>
      );

    case "images":
      return <ArticleImages images={block.images} layout={block.layout} />;

    case "flexibleGrid":
      return <FlexibleGrid items={block.cells} />;

    case "buttons":
      return (
        <div className={clsx("space-y-4 lg:space-y-8")}>
          {!!block.heading && (
            <ArticleHeading size={"lg"} element={"h3"} alignment="center">
              {block.heading}
            </ArticleHeading>
          )}
          <BodyButtons buttons={block.buttons} />
        </div>
      );

    default:
      return (
        <div className={clsx("text-center")}>
          {block.typeHandle} in development
        </div>
      );
  }
};

export default Body;
