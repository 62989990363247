import colourways from "@/lib/colourways";
import clsx from "clsx";

const Spacer = ({ size, backgroundColourwayHandle = "white" }) => {
  const textBackgroundColourway = colourways(backgroundColourwayHandle);

  return (
    <div
      className={clsx(
        "Spacer",
        `Spacer--${size}`,
        [`${textBackgroundColourway.bg}`],
        {
          "h-2 lg:h-4 2xl:h-6": size === "xs",
          "h-4 lg:h-8 2xl:h-12": size === "sm",
          "h-12 lg:h-16 2xl:h-20": size === "md",
          "h-20 lg:h-24 2xl:h-28": size === "lg",
          "h-24 lg:h-36 2xl:h-40": size === "xl",
          "text-white": textBackgroundColourway.useLightText,
          "text-black": !textBackgroundColourway.useLightText,
        }
      )}
    ></div>
  );
};

export default Spacer;
